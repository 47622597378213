import FormUtils from '../helpers/form-utils';
import FormController from './form_controller';

const validationRules = {
  entry_shipper_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_shipper_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_shipper_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_consignee_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Consignee name can't be blank",
  },
  // entry_consignee_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_consignee_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_notify_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_notify_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_notify_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_bl_number: {
    isValid: input => !!input.value,
    errorMessage: "Bl number can't be blank",
  },
  entry_voyage_no: {
    isValid: input => !!input.value,
    errorMessage: "Voyage no can't be blank",
  },
  entry_carrier_id: {
    isValid: input => !!input.value,
    errorMessage: "Carrier can't be blank",
  },
  entry_vessel_id: {
    isValid: input => !!input.value,
    errorMessage: "Vessel can't be blank",
  },
  entry_etd: {
    isValid: input => !!input.value,
    errorMessage: "Etd can't be blank",
  },
  entry_eta: {
    isValid: input => !!input.value,
    errorMessage: "Eta can't be blank",
  },
  entry_country: {
    isValid: input => true,
  },
  entry_port_of_loading_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of loading can't be blank",
  },
  entry_port_of_discharge_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of discharge can't be blank",
  },
  entry_incoterm: {
    isValid: input => !!input.value,
    errorMessage: "Incoterm can't be blank",
  },
  entry_description_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "Description of goods can't be blank",
  },
  entry_cost_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "FOB value can't be blank",
  },
  entry_freight_value: {
    isValid: input => !!input.value,
    errorMessage: "Freight value can't be blank",
  },
  entry_insurance_value: {
    isValid: input => !!input.value,
    errorMessage: "Insurance value can't be blank",
  },
  entry_other_charges: {
    isValid: input => !!input.value,
    errorMessage: "Other charges can't be blank",
  },
  entry_volume: {
    isValid: input => !!input.value,
    errorMessage: "Volume can't be blank",
  },
  entry_shipment_type: {
    isValid: input => !!input.value,
    errorMessage: "Shipment Type can't be blank",
  },
  entry_bill_of_loading_doc: {
    isValid: input => true,
  },
  entry_commercial_invoice_doc: {
    isValid: input => true,
  },
  entry_customs_decleration_doc: {
    isValid: input => true,
  },
};

export default class NewEntryFormController extends FormController {
  constructor(context) {
    super(context, validationRules);
  }
}
