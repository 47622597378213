import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

const SINGULAR_SHIPMENTS = ['bulk', 'genco']

export default class extends Controller {
  static targets = ["box", "header", "type", "action", "totalGrossWeight"];


  async connect() {
    this.clean_state = true;
    this.hideTotalGrossWeight();

    await $(this.typeTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true });
      this.dispatchEvent(event);
    });

    $(this.boxTarget).find('select.select2').each(function () {
      $(this).select2('destroy');
    });

    console.log('select2 destroyed');

    if (this.typeTarget.dataset.editable === 'false') {
      $(this.typeTarget).prop("disabled", true);
      this.adjustButtons(this.typeValue());
    }
    const shipmentType = this.typeValue();

    if (shipmentType === 'Container') {
      this.showTotalGrossWeight();
    }

    this.assocButtons().forEach(link => {
      if (this.isSingularAssociation(shipmentType)) {
        link.classList.add('d-none');
      }
    });

    this.updateRowIndices();

    this.observeDOMChanges();
  }

  observeDOMChanges() {
    const targetNode = this.boxTarget;
    const config = { childList: true, subtree: true };

    const callback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.updateRowIndices();
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
  
  updateRowIndices() {
    const rows = this.assocRows();
    rows.forEach((row, index) => {
      const indexColumn = row.querySelector('.index-number');
      if (indexColumn) {
        indexColumn.textContent = index + 1;
      }
    });
  }

  disconnect() {
    this.removeAllAssocRows();
    this.hideAllAddAssocButtons();
  }

  typeValue() {
    return $(this.typeTarget).select2('data')[0].element.value;
  }

  assocRows() {
    return this.boxTarget.querySelectorAll('.assoc-row');
  }

  assocButtons() {
    return this.boxTarget.querySelectorAll('.add_fields');
  }

  removeAllAssocRows() {
    this.assocRows().forEach(row => row.remove());
  }

  removeIrrelevantAssocRows(shipmentType) {
    this.assocRows().forEach(row => {
      if (!row.dataset.association.startsWith(shipmentType.toLowerCase())) row.remove();
    });
  }

  hideAllAddAssocButtons() {
    this.assocButtons().forEach(link => link.classList.add('d-none'));
  }

  hideAssocButton(element) {
    element.classList.add('d-none');
  }

  showAssocButton(element) {
    element.classList.remove('d-none');
  }

  isSingularAssociation(assocName) {
    return SINGULAR_SHIPMENTS.some(shipment => assocName.toLowerCase().startsWith(shipment));
  }

  setHeader(assocName) {
    const isEmpty = assocName === '';
    if (isEmpty) {
      this.headerTarget.innerText = '';
      return;
    }

    const isSingular = this.isSingularAssociation(assocName);
    this.headerTarget.innerText = isSingular ? assocName : `${assocName}s`;
  }

  showBox(shipmentType) {
    this.setHeader(shipmentType);
    this.boxTarget.classList.remove('d-none');
  }

  isFormDirty() {
    return this.clean_state === false;
  }

  makeFormDirty() {
    if (this.isFormDirty()) this.removeAllAssocRows();
    this.clean_state = false;
  }

  clearEverything() {
    this.removeAllAssocRows();
    this.hideAllAddAssocButtons();
    this.setHeader('');
  }

  adjustButtons(shipmentType) {
    this.assocButtons().forEach(link => {
      link.dataset.assoctype.startsWith(shipmentType.toLowerCase()) ? this.showAssocButton(link) : this.hideAssocButton(link);
    })
  }

  setAssociation() {
    const shipmentType = this.typeValue();

    if (shipmentType === '') {
      this.clearEverything();
      return;
    }

    this.removeIrrelevantAssocRows(shipmentType);
    this.showBox(shipmentType);
    this.makeFormDirty();

    if (this.isSingularAssociation(shipmentType)) {
      this.assocButtons().forEach(link => {
        if (link.dataset.assoctype.startsWith(shipmentType.toLowerCase())) {
          link.click();
        }
      });
      this.hideAllAddAssocButtons();
      return;
    }

    this.adjustButtons(shipmentType);
  }

  removeAssocRow(event) {
    event.currentTarget.closest('.assoc-row').nextElementSibling.value = "true";
    event.currentTarget.closest('.assoc-row').remove();
    this.updateRowIndices();
    return event.preventDefault();
  }

  shipmentTypeChanged(event) {
    const addAssocButton = this.boxTarget.querySelector('.add_fields:not(.d-none)');
    const assocRows = this.boxTarget.querySelectorAll('.assoc-row');
    if (event.target.value === "Container") {
      this.showTotalGrossWeight()
    } else {
      this.hideTotalGrossWeight()
    }
    setTimeout(() => {
      if (assocRows.length === 0) {
        addAssocButton.click();
      }
    }, 100);
  }

  showTotalGrossWeight() {
    this.totalGrossWeightTarget.classList.remove('d-none');
  }

  hideTotalGrossWeight() {
    this.totalGrossWeightTarget.classList.add('d-none');
  }

  duplicateRow(event) {
    event.preventDefault();
    const row = event.currentTarget.closest('.assoc-row');

    $(row).find('select.select2-hidden-accessible').each(function () {
      $(this).select2('destroy');
    });

    const clone = row.cloneNode(true);

    // Update the name and id of the cloned inputs to avoid duplication issues
    const inputs = clone.querySelectorAll('input, select');

    inputs.forEach(input => {
      const name = input.getAttribute('name');
      const id = input.getAttribute('id');

      if (name) {
        const newName = name.replace(/(\d+)/, (match) => parseInt(match) + 1);
        input.setAttribute('name', newName);
      }

      if (id) {
        const newId = id.replace(/(\d+)/, (match) => parseInt(match) + 1);
        input.setAttribute('id', newId);
      }

      if (input.tagName === 'INPUT') {
        input.value = ''; // Clear the value for string inputs
      } else if (input.tagName === 'SELECT') {
        const originalSelect = row.querySelector(`[name="${name}"]`);
        input.value = originalSelect.value; // Copy value for selects
        input.removeAttribute('readonly');
      }
    });

    row.parentNode.insertBefore(clone, row.nextSibling);
    this.updateRowIndices();
  }
}
